import React from 'react';
import Wrapper from "../components/Wrapper/Wrapper";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Button from "../components/UI/Button";

const ProfileUser = () => {
    return (
        <Wrapper>
            <Header/>
            <main>
                <div className="profile-user">
                    <div className="container">
                        <h2 className="title">Профіль користувача</h2>
                        <h4 className="sub-title">Профіль користувача: admin@gmail.com</h4>

                        <ul className="profile-user__list">
                            <li className="profile-user__item">
                                <p><span>Прізвище:</span> Адмін</p>
                            </li>
                            <li className="profile-user__item">
                                <p><span>Email:</span> admin@gmail.com</p>
                            </li>
                            <li className="profile-user__item">
                                <p><span>Телефон:</span> 0967364537</p>
                            </li>
                            <li className="profile-user__item">
                                <p><span>Статус:</span> Диспетчер</p>
                            </li>
                        </ul>

                        <Button
                            title="Повернутись назад"
                        />
                    </div>
                </div>
            </main>
            <Footer/>
        </Wrapper>
    );
};

export default ProfileUser;