import React from 'react';

import {ReactComponent as LineIcon} from "../../images/capabilities-line_icon.svg";

const CapabilitiesItem = (props) => {
    return (
        <li className="capabilities__item">
            {props.icon}
            <p>
                <LineIcon/>
                {props.text}
            </p>
        </li>
    );
};

export default CapabilitiesItem;