import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

export const countryApi = createApi({
    reducerPath: 'countryApi',
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://horobets-app.ppp-industry.com/api/',
    }),
    endpoints: (builder) => ({
        getCounty: builder.query({
            query: () => 'countries'
        }),
    }),
})

export const {useGetCountyQuery} = countryApi;