import React, {forwardRef} from 'react';

import {ReactComponent as ArrowIcon} from "../../images/dark-arrow_icon.svg";

import Select from 'react-select';
import './SelectSearch.css';

const SelectSearch = forwardRef(({
        label,
        icon,
        options,
        placeholder,
        arrow,
        defaultStyles = true,
        isSearchable,
        defaultValue = false,
        ...rest
    }, ref) => {

    const SelectSearchStyles = {
        control: () => ({
            border: 'none',
            paddingLeft: '32px',
            fontSize: '20px',
            lineHeight: '23px',
        }),
        placeholder: () => ({
            fontWeight: 400,
            fontSize: '20px',
            lineHeight: '23px',
            color: '#868686',
            position: 'absolute',
            width: '100%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }),
        indicatorsContainer: () => ({
            display: 'none'
        }),
        singleValue: () => ({
            fontWeight: 400,
            fontSize: '20px',
            lineHeight: '23px',
            color: '#121212',
            position: 'absolute',
            width: '100%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }),
        menu: () => ({
            margin: 0,
            backgroundColor: '#fff',
            borderRadius: '8px',
            borderWidth: '4px 0 0 0',
            borderColor: '#4d72de',
            borderStyle: 'solid',
            position: 'absolute',
            top: '47px',
            width: '100%',
            overflow: 'hidden',
            zIndex: 10,
        }),
        option: (styles, state) => ({
            ...styles,
            fontSize: '16px',
            cursor: 'pointer',
            padding: '5px 10px',
            lineHeight: '20px',
            transition: 'all .3s',
            color: state.isSelected ? '#fff' : '#000',
            backgroundColor: state.isSelected ? '#4d72de' : '#fff',
            "&:hover": {
                color: '#fff',
                backgroundColor: '#4d72de',
            }
        }),
        noOptionsMessage: () => 'Не знайдено',
    }

    return (
        <div className={defaultStyles ? 'select-search__wrapper select-search__wrapper--default' : 'select-search__wrapper'}>
            <label>

                { label ? <span className="select-search__label">{label}</span> : null }

                <div style={{position: 'relative'}}>
                    <span className="select-search-icon">{icon}</span>
                    <Select
                        ref={ref}
                        {...rest}
                        defaultStyles={defaultStyles}
                        styles={SelectSearchStyles}
                        options={options}
                        placeholder={placeholder}
                        className="select-search-container"
                        classNamePrefix="select-search"
                        noOptionsMessage={() => 'Нічого не знайдено!'}
                        isSearchable={isSearchable}
                        defaultValue={() => defaultValue ? options[0] : null}
                    />
                    <span className="select-search-arrow">{arrow ? <ArrowIcon/> : null}</span>
                </div>
            </label>
        </div>
    );
});

export default SelectSearch;