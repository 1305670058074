import React, {useState} from 'react';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.min.css';
import './DatePickerCustom.css';

const days = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Нд']
const months = [
    'Січень',
    'Лютий',
    'Березень',
    'Квітень',
    'Травень',
    'Червень',
    'Липень',
    'Серпень',
    'Вересень',
    'Жовтень',
    'Листопад',
    'Грудень'
]

const locale = {
    localize: {
        day: n => days[n],
        month: n => months[n]
    },
    formatLong: {
        date: () => 'dd-mm-yyyy'
    }
}

const DatePickerCustom = ({icon, wrapperClassName, placeholderText, label}) => {
    const [startDate, setStartDate] = useState(false);

    return (

        <div className="react-datepicker-container">
            {label ? <span className="react-datepicker__label">{label}</span> : null}
            <div className="datepicker-wrapper">
                {icon}
                <DatePicker
                    wrapperClassName={wrapperClassName}
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    locale={locale}
                    placeholderText={placeholderText}
                />
            </div>
        </div>
    );
};

export default DatePickerCustom;