import React from 'react';
import Table from "../components/Table/Table";
import Wrapper from "../components/Wrapper/Wrapper";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";

const MyFlights = () => {
    const flight = {
        type: 'myFlights',
        columns: [
            { path: 'id', name: 'ID' },
            { path: 'date', name: 'Дата' },
            { path: 'from',  name: 'Звідки' },
            { path: 'to',  name: 'Куди' },
            { path: 'station',  name: 'Станції' },
            { path: 'passengers',  name: 'Пасажирів' },
        ],
        rows: [
            { id: 1, date: '08.10.2022',  from: 'Україна (Київ)', to: 'Німеччина', station: 'Польща', passengers: '12' },
            { id: 2, date: '08.10.2022',  from: 'Україна (Київ)', to: 'Німеччина', station: 'Польща', passengers: '12' },
            { id: 3, date: '08.10.2022',  from: 'Україна (Київ)', to: 'Німеччина', station: 'Польща', passengers: '12' },
            { id: 4, date: '08.10.2022',  from: 'Україна (Київ)', to: 'Німеччина', station: 'Польща', passengers: '12' },
        ]
    }

    return (
        <Wrapper>
            <Header/>
            <main>
                <Table
                    title="Мої рейси"
                    id="id"
                    data={flight}
                />
            </main>
            <Footer/>
        </Wrapper>

    );
};

export default MyFlights;