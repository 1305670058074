import React from 'react';
import Wrapper from "../components/Wrapper/Wrapper";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Input from "../components/UI/Input";
import Button from "../components/UI/Button";

const Rent = () => {
    return (
        <Wrapper>
            <Header/>
            <main>
                <form action="" className="form">
                    <div className="container">
                        <h2 className="title">Бронювання</h2>
                        <h4 className="sub-title">Бронювання рейсу: #755</h4>

                        <table className="table table--small table--rent">
                            <thead className="rent-info__head">
                            <tr>
                                <th>Дата</th>
                                <th>Звідки</th>
                                <th>До</th>
                            </tr>

                            </thead>
                            <tbody className="rent-info__body">
                            <tr>
                                <td>08.10.2022</td>
                                <td>Україна</td>
                                <td>Нідерланди</td>
                            </tr>

                            </tbody>
                        </table>

                        <Input
                            title="Кількість місць"
                            type="number"
                            min="1"
                            defaultValue="1"
                        />

                        <Button
                            otherClass="btn-rent"
                            title="Бронювати"
                        />
                    </div>
                </form>
            </main>
            <Footer/>
        </Wrapper>
    );
};

export default Rent;