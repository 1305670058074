import React from "react";
import Wrapper from "../components/Wrapper/Wrapper";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import HorobetsImg from '../images/about-us-img.jpeg'

function AboutUs() {
    return (
        <Wrapper>
            <Header/>
            <main>
                <article className="information-page">
                    <div className="container">
                        <h2>Про нас</h2>
                        <p><img src={HorobetsImg} alt="logo"/></p>
                        <p><a href="https://horobets.com.ua">Horobets</a> - система купівлі та бронювання квитків на автобусні сполучення по Україні та Європі, а також платформа спільних поїздок, що дозволяє швидко та безпечно подорожувати</p>
                        <p>Компанія <a href="https://horobets.com.ua">Horobets</a> - професійний та досвідчений представник на ринку пасажирських перевезень. Напрямок нашої роботи передбачає співпрацю із надійними та досвідченими перевізниками, щоб забезпечити комфорт та високу якість послуг перевезення пасажирів.</p>
                        <p><b>Обирай якість та комфорт!</b></p>
                        <p>На нашій платформі Ви можете придбати білет з урахуванням конкурентної переваги: порівняти комфорт і вартість послуги, час та додаткові пропозиції перевізника.</p>
                        <p>Ви отримуєте цілодобову підтримку диспетчерської служби, що дозволить швидко отримати необхідну інформацію.</p>
                    </div>
                </article>
            </main>
            <Footer/>
        </Wrapper>
    );
}

export default AboutUs;