import React, {forwardRef, useEffect, useState} from 'react';

import {ReactComponent as DefaultIcon} from "../../images/upload-photo_icon.svg";

import './PhotoUpload.css';

const PhotoUpload = forwardRef(({photo, setFile, ...rest}, ref) => {
    const [image, setImage] = useState(null);

    useEffect(() => {
        if (photo) {
            setImage(photo);
        }
    }, [photo])

    function getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result);
            setFile(file);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    return (
        <div className="file">
            <div className="file__wrap">
                {image ? <img src={image} className="file__img" alt=""/> : <DefaultIcon/>}
                <input
                    ref={ref}
                    {...rest}
                    type="file"
                    accept=".png, .jpg, .jpeg"
                       onChange={(e) => {
                           getBase64(e.target.files[0], result => setImage(result))
                       }}
                />
            </div>

            <div className="file__desc">
                <p>Завантажте дійсний файл зображення.</p>
                <p>Розмір зображення не повинен перевищувати 1 МБ.</p>
            </div>

        </div>
    );
});

export default PhotoUpload;