import {configureStore} from '@reduxjs/toolkit'
import authReducer from "./slices/authSlice";
import messageReducer from "../store/slices/messageSlice";
import {profileApi} from "../services/userService";
import {countryApi} from "../services/countryServices";

import {setupListeners} from '@reduxjs/toolkit/query';

export const store = configureStore({
    reducer: {
        auth: authReducer,
        message: messageReducer,
        [profileApi.reducerPath]: profileApi.reducer,
        [countryApi.reducerPath]: countryApi.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([profileApi.middleware, countryApi.middleware]),
})

setupListeners(store.dispatch)