import React from 'react';

import Header from "../components/Header/Header";
import Capabilities from "../components/Сapabilities/Capabilities";
import PopularFlights from "../components/PopularFlights/PopularFlights";
import Footer from "../components/Footer/Footer";
import MainStatistics from "../components/MainStatistics/MainStatistics";
import Wrapper from "../components/Wrapper/Wrapper";
import Table from "../components/Table/Table";
import IntroSlider from "../components/IntroSlider/IntroSlider";
import '../components/MainIntro/MainIntro.css'
import {ReactComponent as CloseIcon} from "../images/close_icon.svg";


import FormSearch from "../components/FormSearch/FormSearch";
import OctobusContainer from "../octobus/OctobusContainer";


class Octobus extends React.Component {
    state = {
        searchSwitcher: true,
        loadingScript: true
    }

    flight2 = {
        type: 'flights',
        columns: [
            {path: 'date', name: 'Дата'},
            {path: 'from', name: 'Звідки'},
            {path: 'to', name: 'Куди'},
        ],
        rows: [
            {id: 1, date: '08.10.2022', from: 'Україна (Київ)', to: 'Німеччина'},
            {id: 2, date: '08.10.2022', from: 'Україна (Київ)', to: 'Німеччина'},
            {id: 3, date: '08.10.2022', from: 'Україна (Київ)', to: 'Німеччина'},
            {id: 4, date: '08.10.2022', from: 'Україна (Київ)', to: 'Німеччина'},
            {id: 5, date: '08.10.2022', from: 'Україна (Київ)', to: 'Німеччина'},
            {id: 6, date: '08.10.2022', from: 'Україна (Київ)', to: 'Німеччина'},
            {id: 7, date: '08.10.2022', from: 'Україна (Київ)', to: 'Німеччина'},
            {id: 8, date: '08.10.2022', from: 'Україна (Київ)', to: 'Німеччина'},
            {id: 9, date: '08.10.2022', from: 'Україна (Київ)', to: 'Німеччина'},
            {id: 10, date: '08.10.2022', from: 'Україна (Київ)', to: 'Німеччина'},
        ]
    }

    componentDidMount() {

        setTimeout(async () => {
            await this.loadScript('https://octobus.cloud/frameapp/js/manifest.js');
        }, 1000)
        setTimeout(async () => {
            await this.loadScript('https://octobus.cloud/frameapp/js/vendor.js');
        }, 2000)
        setTimeout(async () => {
            await this.loadScript('https://octobus.cloud/frameapp/js/app.js', () => {
                setTimeout(() => {
                    this.setState({loadingScript: false});
                }, 450)
            });
        }, 3000)

    }

    setSearchSwitcher(value) {
        this.setState({searchSwitcher: value});
    }

    async loadScript(src, cb = () => {
    }) {
        const s = document.createElement('script');
        s.src = src;
        s.addEventListener('load', cb);
        document.body.appendChild(s);
    }

    octobusHandler(e) {
        if (e.target.classList.contains('octobus__button') && e.target.classList.contains('is-primary')) {
            window.addEventListener('popstate', function() {
                window.location = window.location.origin;
            });

            document.querySelector('.octobus-content').classList.add('opened');
            document.body.style.overflow = 'hidden';
            document.querySelector('.header').classList.add('d-none');
            document.querySelector('.custom-switch').classList.add('d-none');
        }
    }

    render() {
        return (
            <Wrapper>
                <Header/>
                <main>
                    <IntroSlider/>

                    <div
                        className={!this.state.searchSwitcher ? 'custom-switch' : 'custom-switch custom-switch--change'}
                        onClick={() => this.setSearchSwitcher(!this.state.searchSwitcher)}
                    >
                        <span className="selection"></span>
                        <div className="custom-switch-text custom-switch__left">Пошук попутника</div>
                        <div className="custom-switch-text custom-switch__right">Пошук рейсу</div>
                    </div>

                    <div className="main-intro test">
                        {
                            !this.state.searchSwitcher ?
                                (
                                    <div className="main-intro__content">
                                        <h1>lorem ipsum dolor</h1>
                                        <FormSearch/>
                                    </div>
                                )
                                : null
                        }

                        <div className={`cssload-preloader ${this.state.loadingScript ? '' : 'hidden'}`}>
                            <div className="cssload-preloader-box">
                                <div>З</div>
                                <div>а</div>
                                <div>в</div>
                                <div>а</div>
                                <div>н</div>
                                <div>т</div>
                                <div>а</div>
                                <div>ж</div>
                                <div>е</div>
                                <div>н</div>
                                <div>н</div>
                                <div>я</div>
                            </div>
                        </div>

                        <div onClick={this.octobusHandler}
                             className={this.state.searchSwitcher ? 'main-intro__content active octobus-content' : 'main-intro__content hidden octobus-content'}>
                            <button className="octobus-close" onClick={() => {
                                document.location = '/';
                            }}><CloseIcon/></button>
                            <OctobusContainer/>
                        </div>

                    </div>

                    <Capabilities/>
                    <PopularFlights/>
                    <div className="table-main">
                        <Table
                            title="Рейси"
                            subTitle="Найблищі рейси"
                            id="id"
                            data={this.flight2}
                        />
                        <Table
                            subTitle="популярні рейси"
                            id="id"
                            data={this.flight2}
                        />
                    </div>
                    <MainStatistics/>
                </main>

                <Footer/>
            </Wrapper>
        );
    }
}

export default Octobus;