import React from 'react';
import {Route, Routes} from "react-router-dom";
// import Main from "../pages/Main";
import Profile from "../pages/Profile";
import RegistrationFlight from "../pages/RegistrationFlight";
import AddMyFlight from "../pages/AddMyFlight";
import MyFlights from "../pages/MyFlights";
import MyTrips from "../pages/MyTrips";
import Search from "../pages/Search";
import SearchPassengers from "../pages/SearchPassengers";
import Rent from "../pages/Rent";
import ProfileUser from "../pages/ProfileUser";
import Octobus from "../pages/Octobus";
import OfferAgreement from "../pages/OfferAgreement";
import DeliveryAndPayment from "../pages/DeliveryAndPayment";
import PersonalProcessing from "../pages/PersonalProcessing";
import ConfidentialInformation from "../pages/ConfidentialInformation";
import ContactUs from "../pages/ContactUs";
import AboutUs from "../pages/AboutUs";

const AppRouter = () => {
    return (
        <Routes>
            {/*<Route path="/" element={<Main />}/>*/}
            <Route path="/" element={<Octobus />}/>
            <Route path="profile" element={<Profile />}/>
            <Route path="registration-flight" element={<RegistrationFlight />}/>
            <Route path="add-flight" element={<AddMyFlight />}/>
            <Route path="my-flights" element={<MyFlights />}/>
            <Route path="my-trips" element={<MyTrips />}/>
            <Route path="search" element={<Search />}/>
            <Route path="search-passenger" element={<SearchPassengers />}/>
            <Route path="rent" element={<Rent />}/>
            <Route path="profile-user" element={<ProfileUser />}/>
            {/*<Route path="octobus" element={<Octobus />}/>*/}
            <Route path="/offer-agreement" element={<OfferAgreement />}/>
            <Route path="/delivery-payment-return" element={<DeliveryAndPayment />}/>
            <Route path="/personal-processing" element={<PersonalProcessing />}/>
            <Route path="/confidential-information" element={<ConfidentialInformation />}/>
            <Route path="/contact-us" element={<ContactUs />}/>
            <Route path="/about-us" element={<AboutUs />}/>
        </Routes>
    );
};

export default AppRouter;