import React from 'react';

import {ReactComponent as IconOne} from "../../images/capabilities-1_icon.svg";
import {ReactComponent as IconTwo} from "../../images/capabilities-2_icon.svg";
import {ReactComponent as IconThree} from "../../images/capabilities-3_icon.svg";
import {ReactComponent as IconFour} from "../../images/capabilities-4_icon.svg";

import './Capabilities.css';
import CapabilitiesItem from "./CapabilitiesItem";

const capabilitiesData = [
    {text: 'Реєструй маршрут і чекай – пасажир сам тебе знайде!', icon: <IconOne/>},
    {text: 'Реєструйся як пасажир – водій ЗНАЙДЕТЬСЯ!', icon: <IconTwo/>},
    {text: 'Шукай попутників для себе – економ свої витрати і свій час!', icon: <IconThree/>},
    {text: 'Не хочеш чекати? Легко та просто знайди поїздку для себе!', icon: <IconFour/>}
]

const Capabilities = () => {
    return (
        <div className="capabilities">
            <div className="container">
                <h2>Можливості платформи</h2>

                <ul className="capabilities__list">
                    {capabilitiesData.map(({icon, text}, index) =>
                        <CapabilitiesItem key={index} icon={icon} text={text}/>)}
                </ul>
            </div>
        </div>
    );
};

export default Capabilities;