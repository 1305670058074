import React, {useState} from 'react';

import {ReactComponent as StatisticIcon} from '../../images/statistic_icon.svg';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';


const Count = ({count, description}) => {
    const [State ,setState] = useState({visible: false });

    const onChangeVisibility = isActive => {
        setState({ ...State, visible: isActive });
    }


    return (
        <VisibilitySensor onChange={e => onChangeVisibility(e)} active={!State.visible} partialVisibility offset={{bottom:230}}>
            {({ isVisible }) =>
                <div>
                    <StatisticIcon/>
                    {isVisible ? <CountUp end={count} /> : '0'}
                    <p>{description}</p>
                </div>
            }
        </VisibilitySensor>
    );
};

export default Count;
