import React from 'react';
import Input from "../UI/Input";
import SelectSearch from "../UI/SelectSearch";
import {Controller, useForm} from "react-hook-form";
import {register} from "../../store/slices/authSlice";
import {useDispatch} from "react-redux";

const Registration = ({modalLogin, modalRegistration}) => {
    const dispatch = useDispatch();

    const user = [
        {value: 'dispatcher', label: 'Диспетчер'},
        {value: 'passenger', label: 'Пасажир'},
    ]

    const openLogin = (e) => {
        e.preventDefault();
        modalLogin();
        modalRegistration();
    }

    const {formState: {errors, isValid}, getValues, handleSubmit, control} = useForm({mode: 'all'});

    const onSubmit = data => {
        const {name, email, password, tmpRole} = data;

        dispatch(register({name, email, password, tmpRole}))
            .unwrap()
            .then(() => {
                modalRegistration();
                modalLogin();
            })
            .catch(() => {

            });
    }

    return (
        <form className="form-auth" onSubmit={handleSubmit(onSubmit)}>
            <h2>Реєстрація</h2>
            <div className="form-auth__input-wrap">
                <Controller
                    name="name"
                    control={control}
                    defaultValue=""
                    rules={{
                        required: "Введіть ваше ім'я!",
                        minLength: {
                            value: 3,
                            message: "Ім'я повиненно містити не мешне 3 символів"
                        }
                    }}
                    render={({ field }) => (
                        <>
                            <Input className={errors.name && '_error'} title="Ім'я користувача" type="text" placeholder="Ім'я" {...field}>
                                {errors?.name && <small className="field-error">{errors.name?.message}</small>}
                            </Input>
                        </>

                    )}
                />

                <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    rules={
                        { required: "Введіть вашу електронну пошту!",
                            pattern: {
                                value: /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                message: "Невірна адреса електронної пошти!"
                            }
                        }
                    }
                    render={({ field}) => (
                        <>
                            <Input className={errors.email && '_error'} title="Електронна пошта" type="text" placeholder="Пошта" {...field}>
                                {errors?.email && <small className="field-error">{errors.email?.message}</small>}
                            </Input>

                        </>

                    )}
                />

                <Controller
                    name="password"
                    control={control}
                    defaultValue=""
                    rules={{
                        required: "Введіть ваш пароль!",
                        minLength: {
                            value: 8,
                            message: "Пароль повинен містити не мешне 8 символів"
                        }
                    }}
                    render={({ field }) => (
                        <>
                            <Input className={errors.password && '_error'} title="Пароль" type="password" placeholder="Пароль" {...field}>
                                {errors?.password && <small className="field-error">{errors.password?.message}</small>}
                            </Input>
                        </>

                    )}
                />

                <Controller
                    name="confirmPassword"
                    control={control}
                    defaultValue=""
                    rules={{
                        validate: (value) => value === getValues("password")
                    }}
                    render={({ field }) => (
                        <>
                            <Input className={errors.confirmPassword && '_error'} title="Пароль" type="password" placeholder="Повторний пароль" {...field}>
                                {errors.confirmPassword && errors.confirmPassword.type === "validate" && <small className="field-error">Паролі не збігаються!</small>}
                            </Input>
                        </>

                    )}
                />

                <Controller
                    name="tmpRole"
                    control={control}
                    defaultValue="dispatcher"
                    render={({ field }) => <SelectSearch
                        {...field}
                        label="Тип користувача"
                        arrow={true}
                        options={user}
                        isSearchable={false}
                        defaultValue={true}
                        value={user.value}
                        onChange={(val) => {field.onChange(val.value)}}
                        />
                }
                />



            </div>

            <div className="form-auth__buttons">
                <button className="btn" disabled={!isValid}>Реєстрація</button>
                <button className="btn btn--dark" onClick={openLogin}>Увійти</button>
            </div>
        </form>
    );
};

export default Registration;