import React from 'react';

import './Button.css';

const Button = (
        {
            otherClass = '',
            title,
            icon,
            onClick
        }
    ) => {
    return (
        <button className={`btn ${otherClass}`} onClick={onClick}>
            {icon} {title}
        </button>
    );
};

export default Button;