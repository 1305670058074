import React from 'react';
import {Link} from "react-router-dom";

import {ReactComponent as LogoWhite} from "../../images/logo-white.svg";
import {ReactComponent as Visa} from "../../images/visa.svg";
import {ReactComponent as MasterCard} from "../../images/mastercard.svg";

const Footer = () => {
    return (
        <footer className="footer">
            <div className="container">
                <div className="footer__logo">
                    <LogoWhite/>
                    <div className="footer__copyright">Copyright © 2021</div>
                </div>
                <ul className="footer__list">
                    <li className="footer__item"><Link to="/offer-agreement">Договір оферти</Link></li>
                    <li className="footer__item"><Link to="/delivery-payment-return">Доставка і умови придбання</Link></li>
                    <li className="footer__item"><Link to="/about-us">Про нас</Link></li>
                    <li className="footer__item"><Link to="/confidential-information">Конфіденційна інформація</Link></li>
                    <li className="footer__item"><Link to="/personal-processing">Згода на обробку даних</Link></li>
                    <li className="footer__item"><Link to="/contact-us">Контакти</Link></li>
                </ul>
                <ul className="payments__list">
                    <li className="payments__item">
                        <a href="https://www.visa.com.ua/" className="payments__link"><Visa/></a>
                    </li>
                    <li className="payments__item">
                        <a href="https://www.mastercard.ua/uk-ua.html" className="payments__link"><MasterCard/></a>
                    </li>
                </ul>
            </div>

            <div className="footer__copyright-mobile">Copyright © 2021</div>

        </footer>
    );
};

export default Footer;