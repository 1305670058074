import React from 'react';

import imgOne from '../../images/popular-flights-1.jpg';
import imgTwo from '../../images/popular-flights-2.jpg';
import imgThree from '../../images/popular-flights-3.jpg';
import imgFour from '../../images/popular-flights-4.jpg';

import './PopularFlights.css';
import PopularFlightsItem from "./PopularFlightsItem";

const PopularFlightsData = [
    {title: 'Украіна. - Франція', text: 'Lorem ipsum dolor sit amet, consectetur adipiscing', image: imgOne},
    {title: 'Україна - Польща', text: 'Lorem ipsum dolor sit amet, consectetur adipiscing', image: imgTwo},
    {title: 'Україна. - Італія', text: 'Lorem ipsum dolor sit amet, consectetur adipiscing', image: imgThree},
    {title: 'Україна - Швейцарія', text: 'Lorem ipsum dolor sit amet, consectetur adipiscing', image: imgFour},
]

const PopularFlights = () => {
    return (
        <div className="popular-flights">
            <div className="container">
                <h2>Популярні рейси</h2>
                <ul className="popular-flights__list">
                    {PopularFlightsData.map(({title, text, image}, index) =>
                        <PopularFlightsItem
                            key={index}
                            title={title}
                            text={text}
                            image={image}
                        />
                    )}
                </ul>
            </div>
        </div>
    );
};

export default PopularFlights;