import React, {useEffect, useRef, useState} from 'react';

import logo from "../../images/logo-two.png";
import {ReactComponent as PhoneIcon} from "../../images/phone-icon.svg";
import {ReactComponent as TelegramIcon} from "../../images/telegram.svg";
import {ReactComponent as ViberIcon} from "../../images/viber.svg";
import {ReactComponent as EmailIcon} from "../../images/email-icon.svg";
import {ReactComponent as DefaultUserIcon} from '../../images/user-small_icon.svg';
import {ReactComponent as MailIcon} from '../../images/mail_icon.svg';

import './Menu.css';
import './Header.css';

import Login from "../Login/Login";
import Modal from "../Modal/Modal";
import useModal from "../../hook/useModal";
import Registration from "../Registration/Registration";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";

import {useGetProfileQuery} from "../../services/userService";

const Header = () => {
    const dropDownRef = useRef();

    const {token: currentUser, isLoggedIn, isLogout} = useSelector((state) => state.auth);
    const {data, isSuccess, refetch} = useGetProfileQuery();

    useEffect(() => {
        refetch();
    }, [currentUser, isLogout])


    const [userValue, setUserValue] = useState({name: '', photo: '', slug: ''});
    const [show, setShow] = useState(false);
    const [showSubMenu1, setShowSubMenu1] = useState(false);
    const [showSubMenu2, setShowSubMenu2] = useState(false);
    const [isShowingModal1, toggleModal1] = useModal();
    const [isShowingModal2, toggleModal2] = useModal();

    useEffect(() => {

        if (currentUser && data) {
            const {name, photo, roles} = data.profile;
            const slug = roles.map(({slug}) => slug).join('');

            setUserValue({
                name,
                photo,
                slug
            })
        }

    }, [isSuccess]);


    useEffect(() => {
        const handleClickOutSide = e => {
            if (!e.path.includes(dropDownRef.current)) setShow(false);
        }

        document.body.addEventListener('click', handleClickOutSide);

        return () => document.body.removeEventListener('click', handleClickOutSide);
    }, []);


    return (
        <header className="header">
            <div className="container">
                <Link to="/" className="logo"><img src={logo} alt=""/></Link>

                <div className="header__contacts">
                    <a href="https://vb.me/letsChatOnViber" className="only-icon"><ViberIcon/></a>
                    <a href="https://t.me/petrogorobets" className="only-icon"><TelegramIcon/></a>
                    <a href="tel:0987300995"><PhoneIcon/>+38 (098) 730-09-95</a>
                    <a href="mailto:horobets.com.ua@gmail.com"><EmailIcon/> horobets.com.ua@gmail.com</a>
                </div>

                {isLoggedIn && currentUser.token &&
                    <div className="user-info">
                        <Link to="/">
                            <MailIcon/>
                        </Link>
                        <Link to="/profile" title={userValue.name}>
                            {
                                userValue.photo ? <img className="header-logo-user" src={userValue.photo} alt=""/> : <DefaultUserIcon/>
                            }
                        </Link>
                    </div>
                }

                <div ref={dropDownRef} className="burger-wrapper">
                    <button
                        className={show ? 'burger open' : 'burger'}
                        onClick={() => setShow(!show)}
                    >
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>

                    {show && (
                        <nav className={show ? 'menu' : null}>
                            <ul className="menu__list">
                                <li className="menu__item"><Link to="/">Головна</Link></li>
                                {!isLoggedIn &&
                                    <li className="menu__item"
                                        onClick={() => {
                                            setShow(false)
                                            toggleModal1()
                                        }}
                                    ><span>Увійти </span></li>
                                }
                                {!isLoggedIn &&
                                    <li className="menu__item"
                                        onClick={() => {
                                            setShow(false)
                                            toggleModal2()
                                        }}
                                    ><span>Реєстрація</span></li>
                                }

                                {isLoggedIn &&
                                    <>
                                        <li className="menu__item menu__item--dropdown">
                                            <span
                                                className={showSubMenu1 ? 'opened' : ''}
                                                onClick={() => setShowSubMenu1(!showSubMenu1)}
                                            >
                                             Рейси
                                            <svg width="21" height="11" viewBox="0 0 21 11" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1 1L10.5 10L20 1" stroke="white" strokeLinecap="round"/>
                                            </svg>
                                            </span>
                                            {showSubMenu1 &&
                                                <ul className="menu__sub">
                                                    {userValue.slug === 'dispatcher' &&
                                                        <>
                                                            <li className="menu__item">
                                                                <Link to="/">Додати рейс</Link>
                                                            </li>
                                                            <li className="menu__item">
                                                                <Link to="/">Мої рейси</Link>
                                                            </li>
                                                        </>

                                                    }
                                                    <li className="menu__item">
                                                        <Link to="/">Пошук</Link>
                                                    </li>
                                                </ul>
                                            }
                                        </li>

                                        <li className="menu__item menu__item--dropdown">
                                            <span
                                                className={showSubMenu2 ? 'opened' : ''}
                                                onClick={() => setShowSubMenu2(!showSubMenu2)}
                                            >
                                                Пасажири
                                                <svg width="21" height="11" viewBox="0 0 21 11" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1 1L10.5 10L20 1" stroke="white" strokeLinecap="round"/>
                                                </svg>
                                            </span>
                                            {showSubMenu2 &&
                                                <ul className="menu__sub">
                                                    {userValue.slug === 'dispatcher' &&
                                                        <>
                                                            <li className="menu__item">
                                                                <Link to="/">Додати пасажира</Link>
                                                            </li>
                                                            <li className="menu__item">
                                                                <Link to="/">Мої пасажира</Link>
                                                            </li>
                                                            <li className="menu__item">
                                                                <Link to="/">Пошук</Link>
                                                            </li>
                                                        </>
                                                    }

                                                    {userValue.slug === 'passenger' &&
                                                        <>
                                                            <li className="menu__item">
                                                                <Link to="/">Розмістити поїздку</Link>
                                                            </li>
                                                            <li className="menu__item">
                                                                <Link to="/">Мої поїздки</Link>
                                                            </li>
                                                        </>
                                                    }

                                                </ul>
                                            }
                                        </li>
                                    </>
                                }

                                <li className="menu__item menu__item--mobile">
                                    <a href="tel:0687947303" className="mobile-contact"><PhoneIcon/> 0687947303</a>
                                </li>
                                <li className="menu__item menu__item--mobile">
                                    <a href="mailto:horobets.com.ua@gmail.com"
                                       className="mobile-contact"><EmailIcon/> horobets.com.ua@gmail.com</a>
                                </li>
                                <li className="menu__item menu__item--mobile padding-low">
                                    <a href="https://vb.me/letsChatOnViber"
                                       className="mobile-contact"><ViberIcon width="40px" height="40px"/> Viber</a>
                                </li>
                                <li className="menu__item menu__item--mobile padding-low">
                                    <a href="https://t.me/petrogorobets"
                                       className="mobile-contact inherit-styled"><TelegramIcon width="40px" height="40px"/> Telegram</a>
                                </li>
                            </ul>
                        </nav>
                    )}
                </div>

            </div>

            <Modal
                isShowing={isShowingModal1}
                toggle={toggleModal1}
            >
                <Login modalLogin={toggleModal1} modalRegistration={toggleModal2}/>
            </Modal>

            <Modal
                isShowing={isShowingModal2}
                toggle={toggleModal2}
            >
                <Registration modalLogin={toggleModal1} modalRegistration={toggleModal2}/>
            </Modal>


        </header>
    );
};

export default Header;