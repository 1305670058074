import React, {forwardRef} from 'react';

import './CheckBox.css';

const CheckBox = forwardRef(( {title, ...rest}, ref ) => {

    return (
        <div className="input-checkbox-wrapper">
            <label>
                <input
                    ref={ref}
                    {...rest}
                    className="input-checkbox"
                    type="checkbox"
                />
                <div className="input-checkbox-title">
                    <span className="input-checkbox-icon"></span>
                    {title}
                </div>

            </label>
        </div>
    );
});

export default CheckBox;