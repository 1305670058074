import React from 'react';
import Wrapper from "../components/Wrapper/Wrapper";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import SelectSearch from "../components/UI/SelectSearch";
import DatePickerCustom from "../components/UI/DatePickerCustom";
import InputTime from "../components/UI/InputTime";
import Input from "../components/UI/Input";
import Button from "../components/UI/Button";

const AddMyFlight = () => {

    const val = [
        {value: 'chocolate', label: 'Chocolate'},
        {value: 'strawberry', label: 'Strawberry'},
        {value: 'vanilla', label: 'Vanilla'}
    ]

    return (
        <Wrapper>
            <Header/>
            <main>
                <h2 className="title">Розміщення власного рейсу</h2>
                <form action="" className="form">
                    <div className="container">
                        <h4 className="sub-title">Запропонувати свій маршрут</h4>

                        <div className="form__wrap">
                            <Input
                                className="_req"
                                title="Прізвище"
                                type="text"
                                placeholder="прізвище"
                            />

                            <Input
                                className="_req"
                                title="Ім’я"
                                type="text"
                                placeholder="ім’я"
                            />

                            <Input
                                className="_req"
                                title="Терефон"
                                type="text"
                                placeholder="+38 (067) 88-54-321"
                            />

                            <Input
                                className="_req"
                                title="Кількість місць"
                                type="number"
                                min="1"
                                defaultValue="1"
                            />

                            <SelectSearch
                                label="З країни"
                                arrow={true}
                                options={val}
                                placeholder="Обрати"
                            />

                            <SelectSearch
                                label="Місто"
                                arrow={true}
                                options={val}
                                placeholder="Обрати"
                            />

                            <SelectSearch
                                label="До країни"
                                arrow={true}
                                options={val}
                                placeholder="Обрати"
                            />

                            <SelectSearch
                                label="Місто"
                                arrow={true}
                                options={val}
                                placeholder="Обрати"
                            />

                            <DatePickerCustom
                                wrapperClassName="date-picker-default"
                                placeholderText="Дата"
                                label="Дата"
                            />

                            <InputTime
                                label="Час"
                            />

                            <Button
                                title="Розмістити"
                                otherClass="create-flight"
                            />
                        </div>
                    </div>
                </form>
            </main>
            <Footer/>
        </Wrapper>
    );
};

export default AddMyFlight;