import React, {useEffect, useRef, useState} from 'react';

import { v4 as uuidv4 } from 'uuid';

import {ReactComponent as ManIcon} from "../../images/man_icon.svg";
import {ReactComponent as PhoneIcon} from "../../images/phone-icon.svg";
import {ReactComponent as PeopleIcon} from "../../images/people_icon.svg";
import {ReactComponent as UpdateIcon} from "../../images/update_icon.svg";
import {ReactComponent as PageIcon} from "../../images/page_icon.svg";
import {ReactComponent as RemoveIcon} from "../../images/remove_icon.svg";
import {ReactComponent as CarIcon} from "../../images/car_icon.svg";
import {ReactComponent as ArrowIcon} from "../../images/arrow-down_icon.svg";
import {ReactComponent as SwipeIcon} from "../../images/swipe_icon.svg";

import './Table.css';

const Table = (
    {
        title,
        subTitle,
        id,
        data,
        otherClass,
        limitation = true,
        containerClass
    }
) => {
    const additionOptions = {
        flights: {
            labels: [
                'Бронь',
                'Контакти'
            ],
            content: [
                {
                    value: <ManIcon/>,
                    callback: () => {},
                },
                {
                    value: <PhoneIcon/>,
                    callback: () => {},
                }
            ],
        },
        myFlights: {
            content: [
                {
                    value: <PeopleIcon/>,
                    callback: () => {
                        console.log('123')},
                },
                {
                    value: <UpdateIcon/>,
                    callback: () => {},
                },
                {
                    value: <PageIcon/>,
                    callback: () => {},
                },
                {
                    value: <RemoveIcon/>,
                    callback: () => {},
                }
            ],
        },
        trips: {
            content: [
                {
                    value: <RemoveIcon/>,
                    callback: () => {},
                }
            ],
        },
        passenger: {
            content: [
                {
                    value: <CarIcon/>,
                    callback: () => {},
                }
            ],
        }
    }

    const [numberItemsShown, setNumberItemsShown] = useState(7);

    const showMore = () => {
        if (numberItemsShown + 7 <= data.rows.length) {
            setNumberItemsShown(numberItemsShown + 7);
        } else {
            setNumberItemsShown(data.rows.length);
        }
    };

    const tableRef = useRef();

    function isOverflowActive(event) {
        return event.offsetHeight < event.scrollHeight || event.offsetWidth < event.scrollWidth;
    }

    const [iconShow] = useState(false);
    const [overflowActive, setOverflowActive] = useState(false);

    useEffect(() => {
        if (isOverflowActive(tableRef.current)) {
            setOverflowActive(true);
            return;
        }

        setOverflowActive(false);
    }, [isOverflowActive]);

    return (
        <div className={`table-container ${containerClass ? containerClass : ''}`}>
            <div className="container">
                {title ? <h2 className="title">{title}</h2> : null}
                {subTitle ? <h4>{subTitle}</h4> : null}

                <div className="table-scroll" ref={tableRef}>
                    <table className={`table ${otherClass ? otherClass : ''}`} >
                        <thead>
                        <tr>
                            {data.columns.map(({path, name}) => (
                                <th key={path}>{name}</th>
                            ))}
                            {additionOptions[data.type].labels && (
                                additionOptions[data.type].labels.map(label =>
                                    <th
                                        key={uuidv4()}
                                        style={{ textAlign: 'center'}}
                                    >
                                        {label}
                                    </th>
                                )
                                : null
                            )}
                        </tr>
                        </thead>
                        <tbody>
                        {limitation ? (
                                data.rows.slice(0, numberItemsShown).map((rowData) => (
                                    <tr key={rowData[id]}>
                                        {data.columns.map(({ path }) => (
                                            <td key={path}>
                                                {rowData[path]}
                                            </td>
                                        ))}
                                        {additionOptions[data.type].content && (
                                            additionOptions[data.type].content.map(content =>
                                                <td
                                                    key={uuidv4()}
                                                    onClick={content.callback}
                                                    style={{ textAlign: 'center', padding: '20px 13px'}}
                                                >
                                                    {content.value}
                                                </td>
                                            )
                                        )}
                                    </tr>
                                ))
                            )
                            : (data.rows.map((rowData) => (
                                <tr key={rowData[id]}>
                                    {data.columns.map(({ path }) => (
                                        <td key={path}>
                                            {rowData[path]}
                                        </td>
                                    ))}
                                    {additionOptions[data.type].content && (
                                        additionOptions[data.type].content.map(content =>
                                            <td
                                                key={uuidv4()}
                                                onClick={content.callback}
                                                style={{maxWidth: '97px', textAlign: 'center', padding: '20px 13px'}}
                                            >
                                                {content.value}</td>
                                        )
                                    )}
                                </tr>
                            )))}

                        </tbody>
                    </table>
                </div>

                <div style={{position: 'relative'}}>
                    {limitation && numberItemsShown < data.rows.length
                        ? <button className="btn-show-more" onClick={showMore}><ArrowIcon/></button>
                        : null}

                    {!iconShow && !overflowActive ? null : (
                        <div className="swipe-icon"><SwipeIcon /></div>
                    )}
                </div>

            </div>
        </div>
    );
};

export default Table;