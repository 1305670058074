import React from 'react';
import Wrapper from "../components/Wrapper/Wrapper";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import SelectSearch from "../components/UI/SelectSearch";
import DatePickerCustom from "../components/UI/DatePickerCustom";
import Input from "../components/UI/Input";
import Table from "../components/Table/Table";

const SearchPassengers = () => {
    const val = [
        {value: 'chocolate', label: 'Chocolate'},
        {value: 'strawberry', label: 'Strawberry'},
        {value: 'vanilla', label: 'Vanilla'}
    ]

    const flightSearch = {
        type: 'passenger',
        columns: [
            { path: 'date', name: 'Дата рейсу' },
            { path: 'from',  name: 'Звідки' },
            { path: 'to',  name: 'Куди' },
            { path: 'fullName',  name: 'ПІБ' },
            { path: 'passengers',  name: 'Пасажирів' },
        ],
        rows: [
            {
                date: '08.10.2022',
                from: 'Україна',
                to: 'Нідерланди',
                fullName: 'Іван Іванович тел: 0987465844',
                passengers: '12'
            },
            {
                date: '08.10.2022',
                from: 'Україна',
                to: 'Нідерланди',
                fullName: 'Іван Іванович тел: 0987465844',
                passengers: '12'
            },
        ]
    }

    return (
        <Wrapper>
            <Header/>
            <main>
                <h2 className="title">Пошук</h2>

                <form action="" className="form form--search">
                    <div className="container">
                        <h4 className="sub-title">список активних рейсів від пасажирів</h4>

                        <div className="form__wrap">
                            <SelectSearch
                                label="З країни"
                                arrow={true}
                                options={val}
                                placeholder="Обрати"
                            />

                            <SelectSearch
                                label="З міста"
                                arrow={true}
                                options={val}
                                placeholder="Обрати"
                            />

                            <SelectSearch
                                label="До країни"
                                arrow={true}
                                options={val}
                                placeholder="Обрати"
                            />

                            <SelectSearch
                                label="До міста"
                                arrow={true}
                                options={val}
                                placeholder="Обрати"
                            />

                            <DatePickerCustom
                                wrapperClassName="date-picker-default"
                                placeholderText="Дата"
                                label="Дата"
                            />

                            <Input
                                title="Пошук"
                                placeholder="Пошук"
                                type="search"
                            />

                        </div>
                    </div>
                </form>

                <Table
                    otherClass="table--small table--search"
                    data={flightSearch}
                    id="id"
                />
            </main>
            <Footer/>
        </Wrapper>
    );
};

export default SearchPassengers;