import React from 'react';
import Wrapper from "../components/Wrapper/Wrapper";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";

const ConfidentialInformation = () => {
    return (
        <Wrapper>
            <Header/>
            <main>
                <article className="information-page">
                    <div className="container">
                        <h2>КОНФІДЕНЦІЙНА ІНФОРМАЦІЯ.</h2>
                        <ul className="nwf">
                            <li>Користувач підтверджує згоду з тим, що несе особисту відповідальність за надану для збереження конфіденційної інформації.</li>
                            <li>Сайт не несе відповідальності за будь-які збитки, завдані внаслідок несанкціонованого використання конфіденційної інформації залишеної Користувачем.</li>
                            <li>Вся особиста інформація, отримана Сайт в результаті здійснення замовлень через Сайт зберігається згідно з правилами та положеннями про конфіденційність, які є невід'ємною частиною Договору.</li>
                            <li>Сайт залишає за собою право використовувати та розкривати інформацію про Користувача або про замовлення здійсненні Користувачем, на умовах дотримання Конфіденційності.</li>
                            <li>З метою захисту ділових інтересів, Сайт надає відповідь на письмовий запит «Про надання інформації про Постачальника», що надійшов на нашу адресу з обов’язковим попередженням та отриманням дозволу Сторони по відношенні до якої здійснюється запит, у тому числі і за запитами відповідних державних чи інших органів уповноважених законом на отримання такої інформації.</li>
                            <li>Сайт зберігає за собою право змінювати правила Конфіденційності в будь-який час, виключно в сторону покращення, з подальшим розширенням умов збереження конфіденційної інформації.</li>
                        </ul>
                    </div>
                </article>
            </main>
            <Footer/>
        </Wrapper>
    );
};

export default ConfidentialInformation;