import React from 'react';
import Wrapper from "../components/Wrapper/Wrapper";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Table from "../components/Table/Table";

const MyTrips = () => {
    const flight = {
        type: 'trips',
        columns: [
            { path: 'date', name: 'Дата' },
            { path: 'from',  name: 'Звідки' },
            { path: 'to',  name: 'Куди' },
            { path: 'station',  name: 'Станції' },
            { path: 'fullName',  name: 'ПІБ' },
            { path: 'position',  name: 'К-ть місць' },
            { path: 'create',  name: 'Створено' },
            { path: 'offers',  name: 'Пропозиції' },
        ],
        rows: [
            {
                date: '08.10.2022',
                from: 'Україна (Київ)',
                to: 'Німеччина',
                station: 'Польща',
                fullName: 'Іван Іванович тел: 0987465844',
                position: '5',
                create: '22.09.2022 09:30',
                offers: '0'
            },
            {
                date: '08.10.2022',
                from: 'Україна (Київ)',
                to: 'Німеччина',
                station: 'Польща',
                fullName: 'Іван Іванович тел: 0987465844',
                position: '5',
                create: '22.09.2022 09:30',
                offers: '0'
            },
            {
                date: '08.10.2022',
                from: 'Україна (Київ)',
                to: 'Німеччина',
                station: 'Польща',
                fullName: 'Іван Іванович тел: 0987465844',
                position: '5',
                create: '22.09.2022 09:30',
                offers: '0'
            },
            {
                date: '08.10.2022',
                from: 'Україна (Київ)',
                to: 'Німеччина',
                station: 'Польща',
                fullName: 'Іван Іванович тел: 0987465844',
                position: '5',
                create: '22.09.2022 09:30',
                offers: '0'
            },
            {
                date: '08.10.2022',
                from: 'Україна (Київ)',
                to: 'Німеччина',
                station: 'Польща',
                fullName: 'Іван Іванович тел: 0987465844',
                position: '5',
                create: '22.09.2022 09:30',
                offers: '0'
            },
        ]
    }

    const flightTwo = {
        type: 'trips',
        columns: [
            { path: 'date', name: 'Дата' },
            { path: 'from',  name: 'Звідки' },
            { path: 'to',  name: 'Куди' },
            { path: 'fullName',  name: 'ПІБ' },
            { path: 'position',  name: 'К-ть місць' },
        ],
        rows: [
            {
                date: '08.10.2022',
                from: 'Україна (Київ)',
                to: 'Німеччина',
                fullName: 'Іван Іванович тел: 0987465844',
                position: '5',
            },

        ]
    }

    return (
        <Wrapper>
            <Header/>
            <main>
                <div style={{paddingBottom: '67px'}}>
                    <Table
                        title="Мої поїздки"
                        subTitle="Всі"
                        otherClass="table--small"
                        data={flight}
                        id="id"
                    />

                    <Table
                        subTitle="Підтверджені"
                        otherClass="table--small"
                        data={flightTwo}
                        id="id"
                    />
                </div>
            </main>
            <Footer/>
        </Wrapper>
    );
};

export default MyTrips;