import React from 'react';

import '../octobus/octobus-one.css';
import '../octobus/octobus-two.css';
import '../components/MainIntro/MainIntro.css'
import '../octobus/octobus.css';

const OctobusContainer = () => {
    return (
        <>
            <div id="auth" className="noprint"></div>
            <div id="app" data-spa="1" data-ptoken="48bfd63059ee41959fa3c125878a02e0" data-lang="uk"
                 data-env="prod"></div>
        </>
    );
};

export default OctobusContainer;