import React, {useEffect} from 'react';

import './Modal.css';

const Modal = ({ toggle, isShowing, children }) => {

    useEffect(() => {
        const handleEsc = (event) => {
            if (event.key === 'Escape') toggle()
        };

        if (isShowing) window.addEventListener('keydown', handleEsc);

        return () => window.removeEventListener('keydown', handleEsc);

    }, [isShowing, toggle]);

    if (!isShowing) return null;

    return (
        <div className="modal">
            <div className="modal__overlay" onClick={ toggle }></div>
            <div className="modal__wrapper">
                <button className="modal__close" onClick={ toggle }></button>
                { children }
            </div>
        </div>
    )
};

export default Modal;