import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import {EffectFade, Pagination, Autoplay} from "swiper";

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';

import './IntroSlider.css';

const IntroSlideImg = [
    {image: require('../../images/intro-slide-img-1.jpg')},
    {image: require('../../images/intro-slide-img-2.jpeg')},
    {image: require('../../images/intro-slide-img-3.jpeg')},
]

const IntroSlider = () => {
    return (
        <Swiper
            className="intro-slider"
            modules={[Pagination, EffectFade, Autoplay]}
            effect="fade"
            fadeEffect={{
                crossFade: true
            }}
            loop="true"
            pagination={{ clickable: true }}
            slidesPerView={1}
            autoplay={{delay: 5000}}
        >
            {IntroSlideImg.map((img, idx) =>
                <SwiperSlide key={idx}>
                    <img src={img.image} alt=""/>
                </SwiperSlide>
            )}
        </Swiper>
    );
};

export default IntroSlider;