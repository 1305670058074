import React from 'react';
import Wrapper from "../components/Wrapper/Wrapper";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Table from "../components/Table/Table";
import SelectSearch from "../components/UI/SelectSearch";
import DatePickerCustom from "../components/UI/DatePickerCustom";
import Button from "../components/UI/Button";

import {ReactComponent as SearchIcon} from "../images/search-white_icon.svg";

const Search = () => {
    const flightSearch = {
        type: 'flights',
        columns: [
            { path: 'date', name: 'Дата/ час відправлення' },
            { path: 'from',  name: 'Відправлення' },
            { path: 'to',  name: 'Прибуття' },
            { path: 'desc',  name: 'Примітка' }
        ],
        rows: [
            {
                date: '08.10.2022  11:30',
                from: 'Україна',
                to: 'Нідерланди',
                desc: 'Відправка щоденно по всій європі',
            },
            {
                date: '08.10.2022  11:30',
                from: 'Україна',
                to: 'Нідерланди',
                desc: 'Відправка щоденно по всій європі',
            },
            {
                date: '08.10.2022  11:30',
                from: 'Україна',
                to: 'Нідерланди',
                desc: 'Відправка щоденно по всій європі',
            },
            {
                date: '08.10.2022  11:30',
                from: 'Україна',
                to: 'Нідерланди',
                desc: 'Відправка щоденно по всій європі',
            },
        ]
    }

    const val = [
        {value: 'chocolate', label: 'Chocolate'},
        {value: 'strawberry', label: 'Strawberry'},
        {value: 'vanilla', label: 'Vanilla'}
    ]

    return (
        <Wrapper>
            <Header/>
            <main>
                <h2 className="title">Пошук</h2>

                <form action="" className="form form--search">
                    <div className="container">
                        <h4 className="sub-title">форма пошуку</h4>

                        <div className="form__wrap">
                            <SelectSearch
                                label="З країни"
                                arrow={true}
                                options={val}
                                placeholder="Обрати"
                            />

                            <SelectSearch
                                label="З міста"
                                arrow={true}
                                options={val}
                                placeholder="Обрати"
                            />

                            <SelectSearch
                                label="До країни"
                                arrow={true}
                                options={val}
                                placeholder="Обрати"
                            />

                            <SelectSearch
                                label="До міста"
                                arrow={true}
                                options={val}
                                placeholder="Обрати"
                            />

                            <DatePickerCustom
                                wrapperClassName="date-picker-default"
                                placeholderText="Дата"
                                label="Дата"
                            />

                            <div className="form__btn">
                                <Button
                                    title="Пошук"
                                    icon={<SearchIcon/>}
                                />
                            </div>

                        </div>
                    </div>
                </form>

                <Table
                    title="Результати пошуку"
                    otherClass="table--small"
                    containerClass="table--search"
                    data={flightSearch}
                    id="id"
                />
            </main>
            <Footer/>
        </Wrapper>
    );
};

export default Search;