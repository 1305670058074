import axios from "axios";

const API_URL = "https://horobets-app.ppp-industry.com/api/";

const register = (name, email, password, tmpRole) => {
    return axios.post(API_URL + "sanctum/register", {
        name,
        email,
        password,
        tmpRole
    });
};

const login = (email, password) => {
    return axios
        .post(API_URL + "sanctum/login", {
            email,
            password,
        })
        .then((response) => {
            if (response.data.token) {
                localStorage.setItem("token", JSON.stringify(response.data));
            }

            return response.data;
        });
};

const logout = () => {
    localStorage.removeItem("token");
};

const authService = {
    register,
    login,
    logout
};

export default authService;