import React from 'react';

import './InputTime.css';

const InputTime = ({label}) => {
    const today = new Date();
    let time = today.getHours() + ":" + today.getMinutes();

    return (
        <div className="time-picker">
            <label>
                <span>{label}</span>
                <input type="time" defaultValue={time} />
            </label>
        </div>
    );
};

export default InputTime;