import React from 'react';
import Count from "./Count";

import './MainStatistics.css'

const MainStatistics = () => {
    return (
        <div className="statistic container">
            <h2>Статистика</h2>
            <div className="statistic-block">
                <Count count={602} description="Всього рейсів"/>
                <Count count={43} description="Рейсів за останій місяць"/>
                <Count count={342} description="Запропонованих рейсів"/>
            </div>
        </div>
    );
};

export default MainStatistics;