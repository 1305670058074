import React, {forwardRef} from 'react';

import InputMask from 'react-input-mask';
import './Input.css';

const InputPhone = forwardRef((
    {
        title,
        className,
        type,
        placeholder,
        value,
        defaultValue,
        children,
        ...rest
    }, ref
) => {

    return (
        <div className="input-wrap">
            <label>
                <span>{title}</span>
                <InputMask
                    mask='+38 (099) 999-99-99'
                    ref={ref}
                    {...rest}
                    className={className}
                    type={type}
                    placeholder={placeholder}
                    value={value}
                    defaultValue={defaultValue}
                />
                {children}
            </label>
        </div>
    );
});

export default InputPhone;