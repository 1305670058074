import React from 'react';

const PopularFlightsItem = (props) => {
    return (
        <li className="popular-flights__item">
            <img src={props.image} alt=""/>
            <div className="popular-flights__content">
                <h3>{props.title}</h3>
                <p>{props.text}</p>
            </div>
        </li>
    );
};

export default PopularFlightsItem;