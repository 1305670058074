import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {setMessage} from "./messageSlice";

import AuthService from "../../services/authService";

const token = JSON.parse(localStorage.getItem("token"));

export const register = createAsyncThunk(
    "auth/register",
    async ({name, email, password, tmpRole}, thunkAPI) => {
        try {
            const response = await AuthService.register(name, email, password, tmpRole);
            thunkAPI.dispatch(setMessage(response.data.message));
            return response.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue();
        }
    }
);

export const login = createAsyncThunk(
    "auth/login",
    async ({email, password}, thunkAPI) => {
        try {
            const data = await AuthService.login(email, password);
            return {token: data};
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue();
        }
    }
);

export const logout = createAsyncThunk("auth/logout", async () => {
    await AuthService.logout();
});


const initialState = token
    ? {isLoggedIn: true, token, isLogout: false}
    : {isLoggedIn: false, token: null, isLogout: true};

const authSlice = createSlice({
    name: "auth",
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(register.fulfilled, (state) => {
                state.isLoggedIn = false;
            })
            .addCase(register.rejected, (state) => {
                state.isLoggedIn = false;
            })
            .addCase(login.fulfilled, (state, action) => {
                state.isLoggedIn = true;
                state.isLogout = false;
                state.token = action.payload.token;
            })
            .addCase(login.rejected, (state) => {
                state.isLoggedIn = false;
                state.token = null;
            })
            .addCase(logout.fulfilled, (state) => {
                state.isLoggedIn = false;
                state.isLogout = true;
                state.token = null;
            })
    }
});

const {reducer} = authSlice;
export default reducer;