import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

export const profileApi = createApi({
    reducerPath: 'profileApi',
    tagTypes: ['userValues'],
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://horobets-app.ppp-industry.com/api/',
        prepareHeaders: (headers) => {
            const token = JSON.parse(localStorage.getItem("token"));

            if (token) {
                headers.set('authorization', `Bearer ${token.token}`)
            }

            return headers
        },
    }),
    endpoints: (builder) => ({
        getProfile: builder.query({
            query: () => 'user',
            providesTags: ['userValues']
        }),
        editProfile: builder.mutation({
            query: (body) => ({
                url: 'user/edit',
                method: 'POST',
                body
            }),
            invalidatesTags: ['userValues']
        })
    }),
})

export const {useGetProfileQuery, useEditProfileMutation} = profileApi;