import React from 'react';
import Wrapper from "../components/Wrapper/Wrapper";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";

const PersonalProcessing = () => {
    return (
        <Wrapper>
            <Header/>
            <main>
                <article className="information-page">
                    <div className="container">
                        <h2>ЗГОДА НА ОБРОБКУ ПЕРСОНАЛЬНИХ ДАНИХ.</h2>
                        <p>Користувач надає згоду та підтверджує, що дані вказані ним при приданні Квитка правдиві, і Користувач має право вказати їх при купівлі Квитка та залишити для подальшої обробки та збереження, у тому числі, але не обмежуючись, для:</p>
                        <ul className="nwf">
                            <li>передачі їх третій стороні, яка тим чи іншим чином пов’язана з цим Договором, або з метою його повного виконання;</li>
                            <li>додаткового інформування Користувача, про статус виконання рейсу, зміни в його умовах;</li>
                            <li>подальшої обробки замовлень Користувача;</li>
                            <li>отриманні сервісних повідомлень, у тому числі щодо акцій, бонусних програм і знижок, що проводяться Сайт.</li>
                        </ul>
                    </div>
                </article>
            </main>
            <Footer/>
        </Wrapper>
    );
};

export default PersonalProcessing;