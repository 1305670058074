import React, {useEffect} from 'react';
import {useForm, Controller} from 'react-hook-form';
import {useSelector, useDispatch} from "react-redux";

import '../../components/UI/CheckBox.css';
import Input from "../UI/Input";
import CheckBox from "../UI/CheckBox";

import { login } from "../../store/slices/authSlice";
import { clearMessage } from "../../store/slices/messageSlice";


const Login = ({modalLogin, modalRegistration}) => {
    const dispatch = useDispatch();
    const { message } = useSelector((state) => state.message);


    useEffect(() => {
        dispatch(clearMessage());
    }, [dispatch]);

    const openRegistration = (e) => {
        e.preventDefault();
        modalRegistration();
        modalLogin();
    }

    const {formState: {errors, isValid}, handleSubmit, control} = useForm({mode: 'all'});

    const onSubmit = data => {
        const { email, password } = data;

        dispatch(login({ email, password }))
            .unwrap()
            .then(() => {
                modalLogin();
                // navigate("/profile");
                // window.location.reload();
            })
            .catch(() => {
                console.log(message)
            });
    }

    return (
        <form className="form-auth" onSubmit={handleSubmit(onSubmit)}>
            <h2>Вхід</h2>

            {message && <small className="field-error" style={{textAlign: 'center', marginBottom: '15px'}}>Введено неправильний email або пароль.</small>}

            <div className="form-auth__input-wrap">
                <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    rules={
                        { required: "Введіть вашу електронну пошту!",
                            pattern: {
                                value: /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                message: "Невірна адреса електронної пошти!"
                            }
                        }
                    }
                    render={({ field}) => (
                        <>
                            <Input className={errors.email && '_error'} title="Електронна пошта" type="text" placeholder="Пошта" {...field}>
                                {errors?.email && <small className="field-error">{errors.email?.message}</small>}
                            </Input>

                        </>

                    )}
                />

                <Controller
                    name="password"
                    control={control}
                    defaultValue=""
                    rules={{
                        required: "Введіть ваш пароль",
                            minLength: {
                                value: 8,
                                message: "Пароль повинен містити не мешне 8 символів"
                            }
                        }}
                    render={({ field }) => (
                        <>
                            <Input className={errors.password && '_error'} title="Пароль" type="password" placeholder="Пароль" {...field}>
                                {errors?.password && <small className="field-error">{errors.password?.message}</small>}
                            </Input>
                        </>

                    )}
                />
            </div>

            <div className="form-auth__wrap">
                <Controller
                    name="savePsw"
                    defaultValue={false}
                    control={control}
                    render={({ field }) => (
                            <CheckBox title="Запамʼятати мене" {...field} />
                    )}
                />


                <a href="#" className="forget-psw">Забули пароль ?</a>
            </div>

            <div className="form-auth__buttons">
                <button className="btn" onClick={handleSubmit(onSubmit)} disabled={!isValid}>Увійти</button>
                <button className="btn btn--dark" onClick={openRegistration}>Зареєструватись</button>
            </div>
        </form>
    );
};

export default Login;