import React from 'react';
import Wrapper from "../components/Wrapper/Wrapper";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";

const DeliveryAndPayment = () => {
    return (
        <Wrapper>
            <Header/>
            <main>
                <article className="information-page small-mb">
                    <div className="container">
                        <h2>Доставка і оплата</h2>
                        <p>Для замовлення квитка на автобус використовуйте вище наведену форму. На першому етапі зазначте дату поїздки, вкажіть пункти відправлення, прибуття та здійсніть пошук.</p>
                        <p>На другому етапі, з представлених рейсів, оберіть найбільш вдалий, вкажіть кількість квитків.</p>
                        <p>Вірно введена особиста інформація на третьому етапі бронювання квитка є гарантією отримання замовлення. Електронний квиток після оплати онлайн (платіжні сервіси LiqPay, PayPal) буде надіслано на вказану Вами електронну адресу. Якщо способом оплати обрано «Готівка», то в такому разі, на електронну адресу надходить електронна квитанція для сплати в касах автовокзалу.</p>
                        <p>При відсутності квитка прохання перевірити правильність введення електронної пошти.</p>
                        <p><b>Будьте уважні при замовленні! Ми не несемо відповідальності, якщо не вірно вказана особиста інформація замовника!</b></p>
                    </div>
                </article>
                <article className="information-page">
                    <div className="container">
                        <h2>Умови повернення квитка.</h2>

                        <ul className="nwf">
                            <li>
                                Примусове повернення коштів за Квиток:
                                <ul>
                                    <li>У випадку повідомлення Постачальником про скасування рейсу, з тієї чи іншої причини, Сайт здійснює повернення Коштів отриманих в якості оплати Квитка, шляхом яким вони надійшли у 100% розмірі до вартості Квитка, або за наявності, пропонує придбати інший Квиток на рейс того самого або іншого Постачальника.</li>
                                </ul>
                            </li>
                            <li>ЗАУВАЖТЕ, вартість Квитка на рейс іншого постачальника може відрізнятись.</li>
                            <li>Повернення придбаних автобусних квитків на рейси та повернення коштів виконується у відповідності постановою КМУ «Правила перевезення пасажирів на внутрішніх рейсах».</li>
                            <li>
                                У разі відмови Замовника від отримання послуг Перевізника, кошти, які були сплачені, повертаються Замовнику згідно встановлених правил. Сума коштів залежить від часу, який залишається до поїздки:
                                <ul>
                                    <li>більше 2 діб = 100%</li>
                                    <li>більше 12 годин = 75%</li>
                                    <li>більше 1 години = 50%</li>
                                    <li>менше 1 години кошти не повертаються</li>
                                </ul>
                            </li>
                            <li>Процедура повернення коштів наступна. Замовник має зафіксувати на Квитку час відмови від послуги у представника Перевізника та звернутися з письмовою заявою щодо повернення за електронною адресою: <a href="mailto:horobets.com.ua">horobets.com.ua</a>. Термін розгляду заяви складає 14 робочих днів.</li>
                            <li>Більш детальну інформацію про умови та правила повернення квитка можна дізнатись за електронною адресою <a href="mailto:horobets.com.ua">horobets.com.ua</a>, за вказаними контактними телефонами.</li>
                        </ul>
                    </div>
                </article>
            </main>
            <Footer/>
        </Wrapper>
    );
};

export default DeliveryAndPayment;