import React from 'react';
import Wrapper from "../components/Wrapper/Wrapper";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";

const OfferAgreement = () => {
    return (
        <Wrapper>
            <Header/>
            <main>
                <article className="information-page">
                    <div className="container">
                        <p>Будь ласка, уважно прочитайте Договір публічної оферти, правила і умови використання сайту та правила конфіденційності, перед тим, як користуватися послугами сайту.</p>
                        <p>Якщо Ви не згодні з Договором публічної оферти, правилами та умовами, будь ласка, не використовуйте сайт.</p>
                        <p>Зауважте, що Договір публічної оферти, правила і умови можуть змінюватися без попередження. Зміни в Договорі публічної оферти, правилах і умовах набирають чинності з моменту їх публікації на сайті.</p>
                        <p>Здійснення вами оплати є свідченням вашого безумовного акцепту договору публічної оферти та погодження з правилами й умовами, визначеними власником сайту.</p>

                        <h2>ДОГОВІР ПУБЛІЧНОЇ ОФЕРТИ</h2>

                        <p>ФОП Горобець Петро Іванович, ІПН 3382311618, адреса: 47453, с. Білозірка, Кременецький р-н., Тернопільська обл., Україна, пропонує необмеженому колу осіб (далі Користувач) укласти Договір публічної оферти (далі Договір), з метою пошуку та/або придбання квитків за допомогою сайту <a href="https://horobets.com.ua.">Horobets</a></p>
                        <p>Користувач може діяти в межах Договору від свого імені та на свою користь та/або від імені третьої сторони та на її користь, у такому випадку Користувач діє виключно в межах повноважень наданих третьою стороною.</p>
                        <p>Квиток (у тому числі і електронний квиток) - проїзний документ встановленої форми, який дає право пасажиру на одержання транспортних послуг автобусного перевезення, створений в автоматизованій системі Сайт, надсилається на електронну адресу вказану при замовленні або іншим чином видається Користувачу.</p>
                        <p>Користувач і Сайт обізнані із загальними вимогами укладання Договорів, додержання яких є необхідним для чинності правочину, володіючи повним обсягом цивільної дієздатності та цивільної правоздатності, мають відповідні повноваження на укладання такого правочину, повністю усвідомлюючи значення своїх дій та згідно з вільним волевиявленням, яке відповідає внутрішній волі учасників цього правочину, маючи на меті реальне настання правових наслідків, розуміючи правову природу цього правочину, а також свої права та обов’язки за Договором, у відповідності до чинного законодавства уклали цей Договір про нижчевикладене:</p>

                        <ul>
                            <li>
                                ПРЕДМЕТ ДОГОВОРУ.
                                <ul>
                                    <li>За Договором, Сайт надає послуги з пошуку відповідного Постачальника, у відповідності до дати, часу та напрямку обраного Користувачем, оформлення квитків різних Постачальників одним замовленням, попереднього продажу квитків, бронювання місць, замовлення квитка по телефону, переоформлення квитка, пошук оптимального розкладу за запитом Користувача, сервісне обслуговування користувача, у тому числі і цілодобову підтримку Користувача Центром сервісного обслуговування, додаткові послуги за запитами Користувача, а також попередньої оплати Квитка коштами отриманими від Користувача (далі Послуги).</li>
                                    <li>За Послуги, що надаються, Сайт отримує винагороду, що складається із суми коштів, що входять до вартості Квитка та визначені, як Агентська винагорода (оплачується Постачальником) та Сервісний збір (оплачується Користувачем).</li>
                                    <li>Кожне придбання Квитка окремо взятого Постачальника, підпорядковується правилам та умовам саме цього Постачальника і є невід’ємною частиною Договору.</li>
                                    <li>У випадку придбання декількох Квитків одним замовленням, правила і умови одного чи іншого Постачальника можуть відрізнятись.</li>
                                    <li>Недотримання правил і умов Постачальника та/або Сайт може призвести до скасування замовлень і відмову у доступі до Сайту та/або придбаних послуг, у тому і числі і можливістю скористатись Квитком, без повернення витрачених на його придання коштів.</li>
                                    <li>Придбання Квитка Користувачем на пільгових умовах (безкоштовне перевезення і т.п.) відповідно до законодавства України, здійснюється виключно в касі/офісі відповідного Постачальника, або за дозволом відповідного Постачальника.</li>
                                </ul>
                            </li>
                            <li>
                                ПРАВА І ОБОВ’ЯЗКИ СТОРІН.
                                <ul>
                                    <li>
                                        Користувач має право:
                                        <ul>
                                            <li>Отримати послуги за Договором;</li>
                                            <li>Скористатись Квитком, у відповідності до обраної дати, часу, місця відправлення та обраного Постачальника;</li>
                                            <li>Повернути Квиток, на умовах визначених Постачальником та/або Сайт;</li>
                                            <li>Вимагати компенсації завданих збитків, що виникає з умов Договору, шляхом направлення відповідного звернення на адресу Сайт.</li>
                                            <li>У випадку завдання моральної шкоди Користувачу, діями або бездіяльністю Сайт, сума відшкодування, при її доведенні та обґрунтуванні не може перевищувати суму більшу чим вартість Квитка.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        Користувач зобов’язаний:
                                        <ul>
                                            <li>Здійснити попередню оплату Квитка;</li>
                                            <li>Перевірити дату, час, місто/місце відправлення, місто/місце прибуття одразу після придбання Квитка;</li>
                                            <li>Завчасно прибути до місця відправлення;</li>
                                            <li>Повідомити Сайт про неможливість використання Квитка з тієї чи іншої причини;</li>
                                            <li>Ознайомитись з умовами повернення Квитка;</li>
                                            <li>Зв’язатись з Сайт до моменту оплати Квитка, у випадку відсутності, умов повернення придбаного Квитка обраного Постачальника на Сайті.</li>
                                            <li>Купуючи Квиток на двох або більше Постачальників або до пункту здійснення пересадки, за допомогою Сайту, Користувач зобов’язаний самостійно визначити достатню кількість часу для здійснення пересадки, у тому числі якщо така посадка запропонована на Сайті. Рекомендована кількість годин очікування в пункті пересадки становить, для внутрішньо державних рейсів 4 години, для міждержавних 8 годин від моменту прибуття за розкладом.</li>
                                            <li>ЗАУВАЖТЕ, що Користувач зобов’язаний ознайомитись з правилами і умовами обраного Постачальника до придбання квитка обраного Постачальника, тим самим надає згоду виконувати і діяти відповідно до правил та умов Постачальника.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        Сайт має право:
                                        <ul>
                                            <li>Діяти від імені Постачальника, в межах повноважень визначених договірними відносинами;</li>
                                            <li>Укласти договір перевезення між Користувачем та Постачальником, на умовах та в спосіб визначений Постачальником;</li>
                                            <li>Встановити та отримати додаткову винагороду з Користувача, за додатково надані сервіси та/або послуги.</li>
                                            <li>Сайт має право записувати телефонні розмови з Користувачем та/або Пасажиром, у випадку звернення до сервісного центру та/або у випадку обслуговування Користувача/Пасажира та/або необхідності повідомити Користувача/Пасажира та/або отримати додаткові відомості у відношенню будь якого Квитка, здійсненого за допомогою сервісу Сайт та/або Сайту.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        Сайт зобов’язаний:
                                        <ul>
                                            <li>Вчасно повідомити Постачальника про придбаний Квиток, і додаткові послуги оплачені Користувачем;</li>
                                            <li>Надіслати Користувачу Квиток відповідно до форми встановленої і прийнятої Постачальником;</li>
                                            <li>Переказати кошти отримані від Користувача, в якості оплати Квитка, на розрахунковий рахунок Постачальника;</li>
                                            <li>Повідомити Користувача, про зміни в умовах надання послуг (за умови повідомлення про такі зміни Постачальником);</li>
                                            <li>Повідомити Користувача, про зміну або скасування рейсу (у випадку надходження такої інформації від Постачальника);</li>
                                            <li>Прийняти звернення від Користувача, розглянути його самостійно або направити для розгляду відповідному Постачальнику;</li>
                                            <li>Своєчасно повідомити Користувача про розгляд звернення, а у випадку надсилання звернення Постачальнику про результати його розгляду (у випадку надходження такої відповіді на адресу Сайт).</li>
                                        </ul>
                                    </li>
                                    <li>ЗАУВАЖТЕ, Сайт, надає відповідь на звернення, способом аналогічним до його надходження. Надсилання відповіді третій стороні (у тому числі і за проханням Користувача) здійснюватися не буде.</li>
                                </ul>
                            </li>
                            <li>
                                ВЗАЄМОРОЗРАХУНКИ.
                                <ul>
                                    <li>Кошти отримані від Користувача, в рахунок придбання Квитка належать Постачальнику (за виключенням Сервісного збору. Сайт проводить розрахунок за придбаний Користувачем Квиток на умовах і в строки визначені Постачальником.</li>
                                    <li>Користувач одноосібно відповідальний за всі комісійні витрати та інші визначені банком витрати, які можуть бути додані до ціни Квитка або додаткових послуг, емітентом кредитної або дебетної картки, крім випадків, в яких витрати приписані до відповідальності Сайт.</li>
                                    <li>Банківські послуги, пов’язані з переказом коштів в межах Договору, оплачуються Стороною, яка їх здійснює.</li>
                                </ul>
                            </li>
                            <li>
                                ІДЕНТИФІКАЦІЯ КОРИСТУВАЧА.
                                <ul>
                                    <li>У якості ідентифікатора Користувача використовується e-mail адреса та/або номер телефону, що вказується при придбанні Квитка.</li>
                                    <li>Зверненням з номера телефона/e-mail адреси залишеної при придбанні Квитка, дозволяє Сайту ідентифікувати Користувача, як належну Сторону Договору з відповідними правами та обов’язками, і дозволяє здійснювати дії по відношенню, як до Договору загалом, так і до конкретно придбаного Квитка.</li>
                                    <li>Звернення Користувача з іншого номера телефона та/або e-mail адреси наданого при придбанні Квитка, дає право Сайту вимагати від Користувача додатково ідентифікувати себе, як належну сторону Договору.</li>
                                    <li>У випадку неможливості ідентифікувати Користувача, як сторону Договору Сайт залишає за собою право відмовити у розгляді звернення.</li>
                                    <li>Відповідальність за збереження та/або доступ до номера телефона та/або e-mail адреси покладається на Користувача.</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </article>
            </main>
            <Footer/>
        </Wrapper>
    );
};

export default OfferAgreement;