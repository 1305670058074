import React, {useCallback, useEffect, useState} from 'react';
import Wrapper from "../components/Wrapper/Wrapper";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Input from "../components/UI/Input";
import Button from "../components/UI/Button";

import {ReactComponent as ExitIcon} from "../images/exit_icon.svg";
import PhotoUpload from "../components/UI/PhotoUpload";
import {Controller, useForm} from "react-hook-form";
import {useGetProfileQuery, useEditProfileMutation} from "../services/userService";
import InputPhone from "../components/UI/InputPhone";
import {logout} from "../store/slices/authSlice";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";

const Profile = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {data, isSuccess} = useGetProfileQuery();
    const [editUser] = useEditProfileMutation();

    const [file, setFile] = useState(null);
    const [userValue, setUserValue] = useState({
        name: '',
        photo: '',
        email: '',
        last_name: '',
        phone: '',
        slug: ''
    });

    useEffect(() => {

        if (data) {
            const {name, photo, email, last_name, phone, roles} = data.profile;
            const slug = roles.map(({name}) => name).join('');

            setUserValue({
                name,
                photo,
                slug,
                email,
                last_name,
                phone
            })

        }

    }, [isSuccess]);

    const {formState: {errors}, handleSubmit, control, getValues} = useForm();

    function clearEmptyObj(obj) {
        const
            formData = new FormData(),
            objKeys = Object.keys(obj);

        objKeys.forEach(key => {
            if (obj[key]) formData.append(key, obj[key]);
        });

        return formData;
    }

    const handlerEditUser = (data) => {
        if (file) {
            data.photo = file;
        }

        if (data.phone) {
            data.phone = data.phone.replace(/\D/g, '');
        }

        editUser(clearEmptyObj(data));
    }

    const logOut = useCallback(() => {
        dispatch(logout());
        navigate("/");
        window.location.reload();
    }, [dispatch]);

    return (
        <Wrapper>
            <Header/>
            <main>
                <h2 className="title">Профіль</h2>

                {data &&
                    <form action="" className="form" onSubmit={handleSubmit(handlerEditUser)}>
                        <div className="container">
                            <h4 className="sub-title">Мій профіль ({userValue.slug})</h4>
                            <div className="form__wrap">
                                <Controller
                                    name="photo"
                                    control={control}
                                    render={({field}) => (
                                        <PhotoUpload
                                            {...field}
                                            setFile={setFile}
                                            photo={data ? data.profile.photo : null}
                                        />

                                    )}
                                />

                                <Controller
                                    name="name"
                                    control={control}
                                    defaultValue={data ? data.profile.name : ''}
                                    rules={{
                                        minLength: {
                                            value: 3,
                                            message: "Ім'я повиненно містити не мешне 3 символів"
                                        }
                                    }}
                                    render={({field}) => (
                                        <>
                                            <Input
                                                className={errors.name && '_error'}
                                                title="Ім'я користувача"
                                                type="text"
                                                placeholder="Ім'я"
                                                {...field}
                                            >
                                                {errors?.name &&
                                                    <small className="field-error">{errors.name?.message}</small>}
                                            </Input>
                                        </>

                                    )}
                                />

                                <Controller
                                    name="last_name"
                                    control={control}
                                    defaultValue={data ? data.profile.last_name : ''}
                                    render={({field}) => (
                                        <Input
                                            title="Прізвище"
                                            type="text"
                                            placeholder="Прізвище"
                                            {...field}
                                        />
                                    )}
                                />

                                <Controller
                                    name="password"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        minLength: {
                                            value: 8,
                                            message: "Пароль повинен містити не мешне 8 символів"
                                        }
                                    }}
                                    render={({ field }) => (
                                        <>
                                            <Input className={errors.password && '_error'} title="Пароль" type="password" placeholder="Пароль" {...field}>
                                                {errors?.password && <small className="field-error">{errors.password?.message}</small>}
                                            </Input>
                                        </>

                                    )}
                                />

                                <Controller
                                    name="confirmPassword"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        validate: (value) => value === getValues("password")
                                    }}
                                    render={({ field }) => (
                                        <>
                                            <Input className={errors.confirmPassword && '_error'} title="Пароль" type="password" placeholder="Повторний пароль" {...field}>
                                                {errors.confirmPassword && errors.confirmPassword.type === "validate" && <small className="field-error">Паролі не збігаються!</small>}
                                            </Input>
                                        </>

                                    )}
                                />

                                <Controller
                                    name="phone"
                                    control={control}
                                    rules={{
                                        minLength: {
                                            value: 17,
                                            message: "Телефон повинен містити не мешне 12 цифер"
                                        }
                                    }}
                                    defaultValue={data ? data.profile.phone : ''}
                                    render={({field}) => (
                                            <InputPhone
                                                className={errors.phone && '_error'}
                                                title="Номер телефону"
                                                type="text"
                                                placeholder="Номер"
                                                {...field}
                                            >
                                                {errors?.phone && <small className="field-error">{errors.phone?.message}</small>}
                                            </InputPhone>

                                    )}
                                />

                                <Controller
                                    name="email"
                                    control={control}
                                    defaultValue={data ? data.profile.email : ''}
                                    rules={
                                        {
                                            pattern: {
                                                value: /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                message: "Невірна адреса електронної пошти!"
                                            }
                                        }
                                    }
                                    render={({ field}) => (
                                        <>
                                            <Input className={errors.email && '_error'} title="Електронна пошта" type="text" placeholder="Пошта" {...field}>
                                                {errors?.email && <small className="field-error">{errors.email?.message}</small>}
                                            </Input>

                                        </>

                                    )}
                                />

                            </div>

                            <div className="form__btns form__btns--profile">
                                <Button
                                    title="Оновити дані"
                                />

                                <Button
                                    onClick={logOut}
                                    title="Вийти з акаунту"
                                    otherClass="btn--transparent"
                                    icon={<ExitIcon/>}
                                />
                            </div>
                        </div>
                    </form>
                }

            </main>
            <Footer/>
        </Wrapper>
    );
};

export default Profile;