import React, {useEffect, useState} from 'react';

import {ReactComponent as SearchIcon} from "../../images/search_icon.svg";
import {ReactComponent as LocationIcon} from "../../images/location-accent_icon.svg";
import {ReactComponent as CalendarIcon} from "../../images/calendar_icon.svg";

import SelectSearch from "../UI/SelectSearch";
import DatePickerCustom from "../UI/DatePickerCustom";

import './FormSearch.css';
import {useGetCountyQuery} from "../../services/countryServices";

const FormSearch = () => {
    const {data: country, isSuccess} = useGetCountyQuery();

    const [countries, setCountries] = useState([])


    useEffect(() => {
        if (country) {
            let arr = []
            country.data.map(({id, name}) => {
                arr.push({value: id, label: name})
                return setCountries(arr)
            })
        }

    }, [isSuccess])


    return (
        <form className="form-search">
            <div className="form-search__container">
                <SelectSearch
                    defaultStyles={false}
                    icon={<LocationIcon/>}
                    options={countries}
                    placeholder="Країна відправки"
                />
                <SelectSearch
                    defaultStyles={false}
                    icon={<LocationIcon/>}
                    options={countries}
                    placeholder="Країна прибуття"
                />
                <DatePickerCustom
                    icon={<CalendarIcon/>}
                    wrapperClassName="date-picker-custom"
                    placeholderText="Дата відправлення"
                />
                <button className="form-search__btn"><SearchIcon /></button>
            </div>

            <button className="form-search__btn form-search__btn--mobile"><SearchIcon /></button>
        </form>
    );
};

export default FormSearch;