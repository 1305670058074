import React, {forwardRef} from 'react';

import './Input.css';

const Input = forwardRef((
    {
        title,
        className,
        type,
        placeholder,
        max,
        min,
        value,
        defaultValue,
        children,
        ...rest
    }, ref
) => {

    return (
        <div className="input-wrap">
            <label>
                <span>{title}</span>
                <input
                    ref={ref}
                    {...rest}
                    className={className}
                    type={type}
                    placeholder={placeholder}
                    min={min}
                    max={max}
                    value={value}
                    defaultValue={defaultValue}
                />
                {children}
            </label>
        </div>
    );
});

export default Input;