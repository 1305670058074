import React from "react";
import Wrapper from "../components/Wrapper/Wrapper";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";

function ContactUs() {

    return (
        <Wrapper>
            <Header/>
            <main>
                <article className="information-page">
                    <div className="container">
                        <h2>Контакти</h2>
                        <p>Номер телефону - <a href="tel:0687947303">+380687947303</a></p>
                        <p>Viber чат по телефону - <a href="viber://chat/?number=%2B380687947303">+380687947303</a></p>
                        <p>Електронна пошта - <a href="mailto:horobets.com.ua@gmail.com">horobets.com.ua@gmail.com</a></p>
                        <p><a href="https://www.facebook.com/100692985733027/posts/pfbid02h9kngKKANaSR1d69XcPvLubYL6zApPmHujzKuYkPyvtq53Psb7qk8S3tDvpx8JVDl/?">Сторінка Facebook</a></p>
                    </div>
                </article>
            </main>
            <Footer/>
        </Wrapper>
    );
}

export default ContactUs;